var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "copyCommissions",
        size: "md",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [_c("span", [_vm._v(_vm._s(_vm.title))])]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function ({ cancel }) {
            return [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          _vm.reset()
                          cancel()
                        },
                      },
                    },
                    [_vm._v("ANNULLA")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-outline-lisaweb",
                      attrs: {
                        disabled: !_vm.options.length,
                        size: "sm",
                        variant: "outline-success",
                      },
                      on: {
                        click: function ($event) {
                          _vm.$emit("copy", _vm.options)
                          _vm.reset()
                          cancel()
                        },
                      },
                    },
                    [_vm._v("COPIA")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c("b-row", [
        _c("p", {
          staticClass: "col-md-12 mb-3",
          domProps: { innerHTML: _vm._s(_vm.text) },
        }),
      ]),
      _vm._v(" "),
      _c("b-row", [
        _c(
          "div",
          { staticClass: "col-md-8" },
          [
            _c("base-select", {
              attrs: {
                name: "options",
                label: "",
                options: _vm.opts,
                multiple: true,
                taggable: true,
                closeOnSelect: false,
              },
              on: { select: _vm.onOption, remove: _vm.onOption },
              model: {
                value: _vm.options,
                callback: function ($$v) {
                  _vm.options = $$v
                },
                expression: "options",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group col-md-4 align-self-end" },
          [
            _c(
              "b-button",
              {
                attrs: { pressed: _vm.myToggle, variant: "light" },
                on: {
                  "update:pressed": function ($event) {
                    _vm.myToggle = $event
                  },
                  click: _vm.onToggle,
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.myToggle ? "Deseleziona tutti" : "Seleziona tutti")
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }